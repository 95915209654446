export default class ProductList {

    /**
     * Runs every product list initialization
     * @param container
     */
    static init(container) {
        this.initSpinner(container);
    }

    static initSpinner(container) {
        $(container).find('.spinner__input').each((index, item) => {
            if (!$(item).parent().hasClass('bootstrap-touchspin')) {
                $(item).TouchSpin({
                    buttondown_class: 'btn js-touchspin',
                    buttonup_class: 'btn js-touchspin',
                    min: 0,
                    max: parseInt($(item).attr('max'), 10),
                    step: 1,
                });

                var tooltipWrapper = $(item.closest('.spinner')).find('.js-product-quantity-tooltip');
                var tooltipEl = $(item.closest('.spinner')).find('.js-product-quantity-tooltip-item');

                $(item).on('touchspin.on.max', (spinner) => {
                    tooltipWrapper.show();
                    tooltipEl.tooltip('show');
                    setTimeout(function () {
                        tooltipEl.tooltip('hide');
                    }, 1500);
                });
                $(item).on('touchspin.on.startdownspin', (spinner) => {
                    tooltipWrapper.hide();
                    tooltipEl.tooltip('hide');
                });
                $(item).on('change', (spinner) => {
                    if(parseFloat($(item).val()) > parseFloat($(item).attr('max'))){
                      $(item).closest('.product-miniature-buttons__row').find('.js-add-to-cart').attr('disabled', 'disabled');
                        tooltipWrapper.show();
                        tooltipEl.tooltip('show');
                      $(item).trigger("touchspin.updatesettings", {});
                        setTimeout(function () {
                            tooltipEl.tooltip('hide');
                        }, 1500);
                        if(parseFloat($(item).attr('max')) != 0) {
                          setTimeout(function () {
                            $(item).closest('.product-miniature-buttons__row').find('.js-add-to-cart').removeAttr('disabled');
                          }, 500);
                        }
                    }
                    $(item).closest('.spinner').attr('data-total-price', $(item).closest('.spinner').data('price-net') * spinner.target.value);
                    $(item).closest('.spinner').attr('data-product-quantity', spinner.target.value);
                });
            }
        });
    }
}
