import PerfectScrollbar from 'perfect-scrollbar';

export default class PerfectScrollbarClass {

    constructor() {
        /**
         * @type {Array.<PerfectScrollbar>}
         */
        this.scrollbars = [];
    }

    /**
     * Initialize each Scrollbar matched by selector if it wasn't initialized before
     * @see getParams
     *
     * @param {String} selector
     * @returns {Array.<PerfectScrollbar>}
     */
    init(selector) {
        const scrollbars = [];
        $(selector).each((index, item) => {
            scrollbars.push(
                new PerfectScrollbar(item, {
                    wheelPropagation: false,
                    suppressScrollX: true,
                })
            )

        });

        this.scrollbars.push(...scrollbars);
        return scrollbars;
    }
}
