/* global $, prestashop */
$(document).ready(function () {
    $(document).on('change', '.js-qty-input', function () {
        let value = parseInt($(this).val());
        let minValue = parseInt($(this).data('min-cart-qty'));
        let previousValue = parseInt($(this).data('previous-value'));

        if (value % minValue !== 0) {
            let newValue = value >= previousValue ?
                value + minValue - (value % minValue) :
                value - (value % minValue);

            $(this).val(newValue);
            $(this).data('previous-value', newValue);
        } else {
            $(this).data('previous-value', value);
        }
    });

    $(document).on('click', '.js-mass-add-submit', function (e) {
        e.preventDefault();

        const $this = $(this);
        const div = $this.closest('.js-mass-add');

        $.ajax({
            url: div.data('action'),
            method: 'post',
            data: div.closest('form').serialize(),
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    prestashop.emit('updateCart', {
                        reason: {
                            cart: response.cart,
                            products: response.products,
                            idProduct: response.id_product,
                            idProductAttribute: response.id_product_attribute,
                            linkAction: 'add-to-cart',
                        }
                    });

                    const miniature = $this.closest('.product-miniature');
                    if (miniature.length !== 0) {
                        miniature.find('.js-product-variants-button:visible').trigger('click');
                    }
                } else if ('error' in response) {
                    alert(response.error);
                } else {
                    alert(div.data('error-text'));
                }
            },
        });
    });

    $(document).on('click', '.js-variant-add-submit', function (e) {
        e.preventDefault();

        const $this = $(this);
        const div = $(this).closest('.js-mass-add');

        $.ajax({
            url: div.data('action'),
            method: 'post',
            data: $this.parents('.js-product-variants-fieldset').serialize(),
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    prestashop.emit('updateCart', {
                        reason: {
                            cart: response.cart,
                            products: response.products,
                            idProduct: response.id_product,
                            idProductAttribute: response.id_product_attribute,
                            linkAction: 'add-to-cart',
                        }
                    });
                } else if ('error' in response) {
                    alert(response.error);
                } else {
                    alert(div.data('error-text'));
                }
            },
        });
    });
});
