$(document).ready(function () {
  $(document).on('submit', '#product-question-form', function (e) {
    e.preventDefault();

    $.ajax({
      url: $(this).attr('action'),
      method: 'post',
      data: $(this).serialize(),
      dataType: 'json',
      success: function (response) {
        if(response.success) {
          $('.product-question-modal-body').html(response.message);
        } else {
          var $alerts = $('#product-question-modal-alerts');
          $alerts.html('');
          response.errors.forEach(function (error) {
            $alerts.append(error + '<br/>');
          });
        }
      }
    });
  });
});
