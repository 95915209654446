import $ from 'jquery';

export default class FileUploaderManager
{
    constructor() {
        this.initEvents();
    }

    initEvents() {
        this.addChangeToFileUploader();
        this.removeFileFromUploader();
    }

    addChangeToFileUploader() {
        let fileUploaderInput = '.js-csv-add-to-cart-input-file-upload';
        let fileUploaderIcon = '.js-csv-add-to-cart-input-file-icon';
        let fileUploaderLabel = '.js-csv-add-to-cart-input-file-label';
        let fileUploaderWrapper = '.js-csv-add-to-cart-input-file';
        let fileUploaderRemove = '.js-csv-add-to-cart-input-file-remove';
        let $submitCsv = $('.js-submit-csv');
        $(document).on('change', fileUploaderInput, function() {
            let parents = $(this).parents(fileUploaderWrapper);
            if(parents.find(fileUploaderLabel).hasClass('selected')) {
                parents.find(fileUploaderIcon).addClass('hide');
                parents.find(fileUploaderRemove).addClass('visible');
                parents.addClass('selected');
                $submitCsv.removeAttr('disabled');
            }
        });
    }

    removeFileFromUploader() {
        let fileUploaderRemove = '.js-csv-add-to-cart-input-file-remove';
        let fileUploaderWrapper = '.js-csv-add-to-cart-input-file';
        let fileUploaderInput = '.js-csv-add-to-cart-input-file-upload';
        let fileUploaderIcon = '.js-csv-add-to-cart-input-file-icon';
        let fileUploaderLabel = '.js-csv-add-to-cart-input-file-label';
        let $submitCsv = $('.js-submit-csv');
        $(document).on('click', fileUploaderRemove, function() {
            let parents = $(this).parents(fileUploaderWrapper);
            let input = parents.find(fileUploaderInput);
            let label = parents.find(fileUploaderLabel);
            let icon = parents.find(fileUploaderIcon);
            input.replaceWith(input.val('').clone(true));
            label.html(label.data('label-text')).removeClass('selected');
            icon.removeClass('hide');
            $(this).removeClass('visible');
            parents.removeClass('selected');
            $submitCsv.attr('disabled', true);
        });
    }
}
