import EventEmitter from 'events';

/**
 * Class to safe collapsing fields in form's
 */
export default class FormFieldCollapse extends EventEmitter {
  /**
   * @param {object} params
   * @param {string} params.collapse
   * @param {string} params.trigger
   * @param {string} params.triggerEvent
   * @param {boolean} params.activeOnStart
   */
  constructor(params) {
    super();
    this.params = params;
    this.params.triggerEvent = params.triggerEvent || 'click';
    this.isActive = this.params.activeOnStart;
  }

  initEvents() {
    $(this.params.collapse).find('input').prop('required', false);
    if (this.getIsActive()) {
      this.show();
    } else {
      this.hide();
    }

    this.initTrigger();
  }

  /**
   * @returns {boolean}
   */
  getIsActive() {
    return this.isActive;
  }

  /**
   * @param {boolean} value
   */
  setIsActive(value) {
    this.isActive = value;
  }

  show() {
    this.emit('beforeShow', { data: this });
    $(this.params.collapse).addClass('is-active');
    $(this.params.collapse).find('input.required-input').each((index, item) => {
      $(item).prop('required', true);
      $(item).closest('.form-group').find('.js-invalid-feedback-browser').text(item.validationMessage);
    });
    this.setIsActive(true);
    this.emit('afterShow', { data: this });
  }

  hide() {
    this.emit('beforeHide', { data: this });
    $(this.params.collapse).removeClass('is-active');

    $(this.params.collapse).find('input').each((index, item) => {
      $(item).prop('required', false);
      // clear value for pattern fields, to prevent pattern mismatch error on hidden field
      if (typeof $(item).attr('pattern') !== 'undefined') {
        $(item).val('');
      }
    });
    this.setIsActive(false);
    this.emit('afterHide', { data: this });
  }

  initTrigger() {
    $(document).on('click', this.params.trigger, () => {
      this.emit('beforeTriggerEvent', { data: this });
      if (this.getIsActive()) {
        this.hide();
      } else {
        this.show();
      }
      this.emit('afterTriggerEvent', { data: this });
    });
  }
}
