import SwiperSlider from './SwiperSlider';

export default class ProductGallery {
    init() {
        this.initSliders();
    }

    initSliders() {
        this.swiperSlider = new SwiperSlider({
            loop: false,
        });

        this.thumbs = this.swiperSlider.init('.product-thumbs', {
            slidesPerView: 3,
            slideToClickedSlide: true,
            spaceBetween: 21,
            navigation: {
                nextEl: '.js-product-thumbs-next',
                prevEl: '.js-product-thumbs-prev',
            },
        });

        this.covers = this.swiperSlider.init('.products-imagescover', {
            navigation: {
                nextEl: '.js-product-cover-next',
                prevEl: '.js-product-cover-prev',
            },
        });

        if (this.covers.length && this.thumbs.length) {
            this.covers[0].on('slideChange', () => {
                this.thumbs[0].slideTo(this.covers[0].activeIndex, 200);

                $(this.thumbs[0].wrapperEl).find('.product-thumb').removeClass('selected');
                $(this.thumbs[0].slides[this.covers[0].activeIndex]).find('.product-thumb').addClass('selected');
            });

            this.thumbs[0].on('click', (el) => {
                this.covers[0].slideTo(this.thumbs[0].clickedIndex, 200);
            });

            this.thumbs[0].on('slideChange', () => {
                this.covers[0].slideTo(this.thumbs[0].activeIndex, 200);
            });
        }
    }
}
