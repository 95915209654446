import $ from 'jquery';
import prestashop from 'prestashop';

export default class ListVariants {
    init(){
        this.addButtonEvent();
    }

    addButtonEvent(){
        $(document).on('click', '.js-product-variants-button', function (e){
            e.preventDefault();
            const $this = $(this);
            const $currentProductMiniature = $this.parents('.js-product-miniature');
            const $variantContainer = $currentProductMiniature.find('.js-product-miniature-variants-container');
            const $variantButtonText = $currentProductMiniature.find('.js-product-variants-button-text');
            let $variantBackdrop = $('.js-product-variants-backdrop');
            let $variantScrollContainer = $currentProductMiniature.find('.js-scroll-container');

            if(!$variantBackdrop.length){
                $('body').append(`<div class='product-miniature-variants__backdrop js-product-variants-backdrop'></div>`);
                $('.js-product-variants-backdrop').fadeIn();
            }else{
                $($variantBackdrop).fadeOut(function(){
                    $variantBackdrop.remove();
                });
            }
            $variantButtonText.toggle();
            $currentProductMiniature.toggleClass('product-miniature--variants-opened');
            $variantContainer.toggle();
            if(!$variantScrollContainer.hasClass('ps--active-y')){
                prestashop.emit('openVariantPopup', {
                    reason: 'openVariantPopup',
                    event: e,
                });
            }
        });

        $(document).on('click', '.js-product-variants-backdrop', function (e){
            const $this = $('.product-miniature--variants-opened .js-product-variants-button');
            const $currentProductMiniature = $this.parents('.js-product-miniature');
            const $variantContainer = $currentProductMiniature.find('.js-product-miniature-variants-container');
            const $variantButtonText = $currentProductMiniature.find('.js-product-variants-button-text');
            let $variantBackdrop = $('.js-product-variants-backdrop');
            let $variantScrollContainer = $currentProductMiniature.find('.js-scroll-container');


            $($variantBackdrop).fadeOut(function(){
                $variantBackdrop.remove();
            });

            $variantButtonText.toggle();
            $currentProductMiniature.toggleClass('product-miniature--variants-opened');
            $variantContainer.toggle();

            if(!$variantScrollContainer.hasClass('ps--active-y')){
                prestashop.emit('openVariantPopup', {
                    reason: 'openVariantPopup',
                    event: e,
                });
            }
        });
    }
}
