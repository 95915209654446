import FileUploaderManager from './components/FileUploaderManager';

/* global $, prestashop */
$(function () {
    const fileUploaderManager = new FileUploaderManager();

    $(document).on('submit', '#csv-add-to-cart-form', function (e) {
        e.preventDefault();

        let $form = $(this);
        $.ajax({
            url: $form.attr('action'),
            method: 'post',
            data: new FormData($form[0]),
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                const alerts = $('#csv-modal-alert');
                if (response.success) {
                    if (response.warnings.length) {
                      alerts.addClass('alert-warning').html(response.warnings.join('<br>')).show();
                    } else {
                      alerts.addClass('alert-success').html(response.message).show();
                    }
                    prestashop.emit('updateCart', {
                        reason: {
                            cart: prestashop.cart,
                        }
                    });
                } else {
                  alerts.addClass('alert-danger').html(response.error).show();
                }
                $('#csv-add-to-cart-modal').on('hidden.bs.modal', function (e) {
                  alerts.hide();
                })
            }
        });
    });
});
