$(document).ready(function() {
  $(document).on('change', '.js-corporation-select', function(e) {
    $.ajax({
      url: $(this).data('change-url'),
      dataType: 'json',
      data: {
        id_corporation: $(this).val(),
      },
      success: function(response) {
        $('.js-department-select').html(response.options).niceSelect('update');
      }
    })
  });
});
