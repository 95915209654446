import $ from "jquery";

$(document).ready(function(){
    if ($('body#contact').length){
        initDistrictsMap();
    }
});

function initDistrictsMap(){
  // showDistrictLabel($('.interactive-map .province[chosen="1"]')[0]);

  var debounceReloadUserTrader = debounce(function(slug){
    reloadUserTrader(slug);
  }, 500);

  $(document).on('click', '.interactive-map .province:not([disabled])', function(e){
    e.preventDefault();
    if($(this).attr("chosen") !== 1){
      chooseDistrict(this);
      debounceReloadUserTrader($(this).attr('slug'));
    }
  });

  // $(window).on('resize', debounce(function(){
  //   showDistrictLabel($('.interactive-map .province[chosen="1"]')[0]);
  // }, 50));
}

function chooseDistrict(pathElement){
  $('.interactive-map').append(pathElement);
  // showDistrictLabel(pathElement);
  $('.interactive-map .province').attr('chosen', 0);
  $(pathElement).attr('chosen', 1);
}

function showDistrictLabel(pathElement) {
  var label = $('.contact-map__label');
  var map = $('.interactive-map');

  label.show();
  label.html($(pathElement).attr("name"));
  var top = $(pathElement).offset().top - map.offset().top + (pathElement.getBoundingClientRect().height / 2);
  var left = $(pathElement).offset().left - map.offset().left + (pathElement.getBoundingClientRect().width / 2);
  label.css({
    top: top,
    left: left
  });
}

function reloadUserTrader(provinceSlug) {
  var url = $('.contact-map').data('refresh-url');
  $('.province-trader__container').addClass('province-trader__container--loading');
  $.ajax({
    url:url,
    data: {
      slug: provinceSlug,
      ajax: true,
      action: 'GetTraderBySlug'
    },
    success: function(data){
      $('.js-trader-container').html(data.provinceTrader);
      $('.js-contact-trader-province').html(data.provinceName);
      $('.province-trader__container').removeClass('province-trader__container--loading');
    }
  });
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
